import React from 'react';
//import 'aframe';

import ReactDOM, { render } from 'react-dom';


class Immersive extends React.Component { 

  //the states of emotion and source will be set to null initially until the user had filled out the form.
  constructor() {
    super();
    this.state = { 
      currentImage: 0,
    };
  }

  componentDidMount()
  {
    
  }

  
  render()
  {  
    /*let list = [Snippet1, Snippet2, Snippet3, Snippet4, Snippet5]
    list = list.sort(() => Math.random() - 0.5)*/
    /*var today = new Date();
    var date = (today.getMonth()+1)+'-'+today.getDate()*/

    return (   
      <div className="container"> {/* DO NOT REMOVE THIS DIV COMPONENT*/}
      {/*<h1>AUGMENTED REALITY</h1>
      <AFrameRenderer arToolKit={{ sourceType: 'webcam' }} >
        <Marker parameters={{ preset: 'hiro' }}>

          <a-box color="blue" material="opacity: 1;" position="0 0.09 0" scale="0.4 0.8 0.8">
            <a-animation attribute="rotation" to="360 0 0" dur="5000" easing="linear" repeat="indefinite" />
          </a-box>

        </Marker>
    </AFrameRenderer>*/}
    <center>
<div class="glitch-embed-wrap">
  <iframe
    src="https://glitch.com/embed/#!/embed/fdpo-immersive?path=index.html&previewSize=100"
    title="FOR IMMERSIVE EXPERIENCES ONLY"
    allow="geolocation; microphone; camera; midi; vr; encrypted-media"
    style={{height: '100%', width: '100%', border: '0'}}>
  </iframe>
  
</div>
</center>

        
      </div>

      )
  }
}

export default Immersive;


