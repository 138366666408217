import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import FDPO from './content/NavBar.png';
import Footer from './content/footer.png';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Countdown from 'react-countdown';
import './FDPO.css';
import Launch from './Launch';

class Touch extends React.Component { 

  //the states of emotion and source will be set to null initially until the user had filled out the form.
  constructor() {
    super();
  }

  componentDidMount()
  {
    
  }
  
  render()
  {  
    
    
    return (   
      <div style={{background: 'black'}}> {/* DO NOT REMOVE THIS DIV COMPONENT*/}
        <div className="container">
          <br></br>
        <Image src={FDPO} className="launchLogo"></Image>
            
            <br></br>
            
            <center>
                THANK YOU, WE'LL BE IN TOUCH.
            </center>
          </div>
     
        
      </div>

      )
  }
}
export default Touch;


