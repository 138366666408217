import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Immersive from './experimental/Immersive';
import FDPO from './content/NavBar.png';
import Cover from './content/cover.png';
import Tracklist from './content/tracklist.png';
import Spotify from './content/spotify.png';
import Apple from './content/appleMusic.png';
import Youtube from './content/youtube.png';
import Footer from './content/footer.png';
import Image from 'react-bootstrap/Image';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Countdown from 'react-countdown';
import './FDPO.css';

var oculus = ['https://fdpocontent.b-cdn.net/oculus/1.png', 'https://fdpocontent.b-cdn.net/oculus/2.png', 'https://fdpocontent.b-cdn.net/oculus/3.png', 'https://fdpocontent.b-cdn.net/oculus/4.png', 'https://fdpocontent.b-cdn.net/oculus/5.png', 'https://fdpocontent.b-cdn.net/oculus/6.png', 'https://fdpocontent.b-cdn.net/oculus/7.png', 'https://fdpocontent.b-cdn.net/oculus/o1.gif', 'https://fdpocontent.b-cdn.net/oculus/o2.gif']
var discotech = ['https://fdpocontent.b-cdn.net/discotech/1.png', 'https://fdpocontent.b-cdn.net/discotech/2.png', 'https://fdpocontent.b-cdn.net/discotech/3.png', 'https://fdpocontent.b-cdn.net/discotech/4.png', 'https://fdpocontent.b-cdn.net/discotech/5.png', 'https://fdpocontent.b-cdn.net/discotech/6.png', 'https://fdpocontent.b-cdn.net/discotech/7.png', 'https://fdpocontent.b-cdn.net/discotech/8.png', 'https://fdpocontent.b-cdn.net/discotech/9.png', 'https://fdpocontent.b-cdn.net/discotech/10.png', 'https://fdpocontent.b-cdn.net/discotech/11.png', 'https://fdpocontent.b-cdn.net/discotech/dt1.gif']
var lastChance = ['https://fdpocontent.b-cdn.net/lastChance/1.png', 'https://fdpocontent.b-cdn.net/lastChance/2.png', 'https://fdpocontent.b-cdn.net/lastChance/3.png', 'https://fdpocontent.b-cdn.net/lastChance/4.png', 'https://fdpocontent.b-cdn.net/lastChance/5.png', 'https://fdpocontent.b-cdn.net/lastChance/6.png', 'https://fdpocontent.b-cdn.net/lastChance/7.png', 'https://fdpocontent.b-cdn.net/lastChance/8.png', 'https://fdpocontent.b-cdn.net/lastChance/9.png', 'https://fdpocontent.b-cdn.net/lastChance/lc2.gif', 'https://fdpocontent.b-cdn.net/lastChance/lc3.gif', 'https://fdpocontent.b-cdn.net/lastChance/lc4.gif'] 
var snippets = ['https://fdpocontent.b-cdn.net/snippets/1.jpg', 'https://fdpocontent.b-cdn.net/snippets/2.jpg', 'https://fdpocontent.b-cdn.net/snippets/3.jpg', 'https://fdpocontent.b-cdn.net/snippets/4.jpg', 'https://fdpocontent.b-cdn.net/snippets/5.jpg', 'https://fdpocontent.b-cdn.net/snippets/6.jpg', 'https://fdpocontent.b-cdn.net/snippets/7.jpg']
var misc = ['https://fdpocontent.b-cdn.net/misc/1.png', 'https://fdpocontent.b-cdn.net/misc/2.png', 'https://fdpocontent.b-cdn.net/misc/3.png', 'https://fdpocontent.b-cdn.net/misc/4.png', 'https://fdpocontent.b-cdn.net/misc/3dgifmaker11923.gif', 'https://fdpocontent.b-cdn.net/misc/9.png', 'https://fdpocontent.b-cdn.net/misc/13.png', 'https://fdpocontent.b-cdn.net/misc/IMG_5871.jpg', 'https://fdpocontent.b-cdn.net/misc/IMG_5872.jpg', 'https://fdpocontent.b-cdn.net/misc/IMG_6937.PNG', 'https://fdpocontent.b-cdn.net/misc/8.gif', 'https://fdpocontent.b-cdn.net/misc/7.png', 'https://fdpocontent.b-cdn.net/misc/33.png']
var bsides = ['https://fdpocontent.b-cdn.net/b-sides/mermaid.wav', 'https://fdpocontent.b-cdn.net/b-sides/ok%20shordie%20(ft.%20Nick%20Chase).mp3', 'https://fdpocontent.b-cdn.net/b-sides/BACARDI%20BUCKET%20(ft.%20Sean%20Capistrano).wav', 'https://fdpocontent.b-cdn.net/b-sides/thoughts%20(voice%20memo).wav']
var imageArray = oculus.concat(discotech).concat(lastChance).concat(misc)


class Main extends React.Component { 

  constructor() {
    super();
    this.state = { 
      page: "Home",
      inspoPlaylist: "Spotify"
    };
    this.pageSelect = this.pageSelect.bind(this)
    this.inspoSelect = this.inspoSelect.bind(this)
  }

  componentDidMount()
  {
    
  }

  pageSelect = (requestedPage) => {
    if(requestedPage.target.innerText === "Inspo")
    {
      document.getElementsByClassName("lazyloadInspo")[0].style.display = "block"
    } 
    else 
    {
      document.getElementsByClassName("lazyloadInspo")[0].style.display = "none"
    }
    if(requestedPage.target.innerText === "Videos") 
    {
      document.getElementsByClassName("lazyloadVideos")[0].style.display = "block"
    } 
    else 
    {
      document.getElementsByClassName("lazyloadVideos")[0].style.display = "none"
    }
    /*if(requestedPage.target.innerText === "Stream") 
    {
      document.getElementsByClassName("bandcamp")[0].style.display = "block"
     
    } 
    else 
    {
      document.getElementsByClassName("bandcamp")[0].style.display = "none"
     
    }*/
    this.setState({page: requestedPage.target.innerText})
  }

  inspoSelect = (provider) => {
    this.setState({inspoPlaylist: provider.target.innerText})
  }

  
  render()
  {  
    let dropDate = new Date("13 Aug 2021 16:00:00 EST")

    return (   
      <div style={{background: 'black'}}> {/* DO NOT REMOVE THIS DIV COMPONENT*/}
        
        <div className="container">
            <br></br>
            <Image src={FDPO} className="launchLogo"></Image>
            <center><h1>FOR DEMONSTRATION<br></br>PURPOSES ONLY</h1></center>
            <DropdownButton size="lg" id="dropdown-basic-button" title={this.state.page} onClick={e => e.preventDefault()} style={{margin: '0 auto', marginTop: '10px'}}>
                <Dropdown.Item href="Home"  onClick={e => this.pageSelect(e)}>Home</Dropdown.Item>
                <Dropdown.Item href="Videos" onClick={e => this.pageSelect(e)}>Videos</Dropdown.Item>
                <Dropdown.Item href="Inspo" onClick={e => this.pageSelect(e)}>Inspo</Dropdown.Item>
                <Dropdown.Item href="Stream" onClick={e => this.pageSelect(e)}>Stream</Dropdown.Item>
                <Dropdown.Item href="Stream" onClick={e => this.pageSelect(e)}>B-Sides</Dropdown.Item>
                <Dropdown.Item href="What's Next?" onClick={e => this.pageSelect(e)}>What's Next?</Dropdown.Item>
            </DropdownButton>
            <br></br>
            <center>
            <div className="fades" style={{display: this.state.page === 'Home' ? 'block' : 'none'}}>
            
           
              <Image src={Cover} style={{margin: '0 auto'}}></Image>
              <br></br> <Image src={Tracklist} style={{margin: '0 auto'}}></Image>
              <br></br><br></br>
                <h1>A DEMO TAPE BY<br></br>zl!ster<br></br>
                <br></br>STREAM NOW</h1>
              
              <div>
                <a href="https://open.spotify.com/album/0kSAdTASiHOzQn6krJog0o?si=ApTFEOv4Q5GOL5NeFhzsgw&dl_branch=1" target="_blank">
                  <Image src={Spotify} className="streaming"></Image>
                </a>
                <br></br>
                <a href="https://music.apple.com/us/album/for-demonstration-purposes-only/1576554323" target="_blank">
                  <Image src={Apple} className="streaming"></Image>
                </a>
                <br></br>
              </div>
             
              <br></br>
             <h1>  <a href="https://www.instagram.com/zlster/?hl=en" target="_blank">INSTAGRAM</a></h1>
              
             <br></br>
            </div>

            {this.state.page === "Videos" && <div>
              
            
            </div>}
           
             <div className="fades" style={{display: this.state.page === "What's Next?" ? 'block' : 'none'}}>
              <br></br>
              <h1>WHAT'S NEXT?</h1>
              <br></br>
              <h2>... a whirlwind begins to form <br></br>(2022)</h2>
              <br></br>
              <h2><a href="https://www.instagram.com/zlster/?hl=en" target="_blank">instagram</a><br></br></h2>
              <br></br>
            </div>
            </center>
        </div>

        
        {this.state.page === 'Stream' && <div className="fades">
        <center>
                <a href="https://open.spotify.com/artist/5q70XbWzSCjlnxUstVSYzw?si=I7YIoETSSyeX09jkRfxbjA&dl_branch=1" target="_blank">
                  <Image src={Spotify} className="streamingMini"></Image>
                </a>
                <br></br>
                <a href="https://music.apple.com/us/artist/zl-ster/1544086565" target="_blank">
                  <Image src={Apple} className="streamingMini"></Image>
                </a>
                <br></br>
                <br></br>
                <h1>ON-SITE STREAM</h1>
               
            <iframe className="bandcamp" src="https://bandcamp.com/EmbeddedPlayer/album=2708013870/size=large/bgcol=ffffff/linkcol=7137dc/transparent=true/" seamless><a href="https://zlister.bandcamp.com/album/for-demonstration-purposes-only-2">FOR DEMONSTRATION PURPOSES ONLY by zl!ster</a></iframe>
            
          
                
                </center>
          </div>}
          
        <div className="fades" style={{display: this.state.page === 'B-Sides' ? 'block' : 'none'}}>
        <center>
        {bsides.map(item => ( <div>
          <br></br>
            <audio controls>
              <source src={item}/>
            </audio>
            <br></br>
          </div>
        ))}
            
          
                
                </center>
          </div>

          <div className="lazyloadVideos">
            <center className="fades">
            <iframe src="https://www.youtube.com/embed/ZNXhjrj7byY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/z18ItXI0vUQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/FI_r0dfwv_s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/J8AedyKeDRo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/sKWhiOgIUpk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/XDHTl1Vd4RQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/UqirJSuCffY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/RIazQElooXE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
            
            {/*
            https://www.youtube.com/embed/XDHTl1Vd4RQ
            https://www.youtube.com/embed/FI_r0dfwv_s
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/gmwgtIxkqi8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <br></br>
            <iframe src="https://www.youtube.com/embed/y0y_PCn6eFg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>*/}
            <h1>SHOT BY <br></br><a href="https://www.instagram.com/drewspliffland/?hl=en" target="_blank">ANDREW STRICKLAND</a></h1>
            <br></br>
            </center>
            </div>
          <div className="lazyloadInspo">
          <center className="fades">
          {imageArray.map(image => (<div>
                
                
                <Image src={image} className="array" onLoad={(event) => event.target.style.display = 'inline-block'} onError={(event) => event.target.style.display = 'none'}></Image>
                <br></br>
                <br></br>
                
              </div>) )}
            <DropdownButton size="md" id="dropdown-basic-button" title={this.state.inspoPlaylist} onClick={e => e.preventDefault()} style={{margin: '0 auto', marginTop: '10px'}}>
                <Dropdown.Item href="Home"  onClick={e => this.inspoSelect(e)}>Apple Music</Dropdown.Item>
                <Dropdown.Item href="Videos" onClick={e => this.inspoSelect(e)}>Spotify</Dropdown.Item>
            </DropdownButton>
            <br></br>

            <div style={{display: this.state.inspoPlaylist === 'Apple Music' ? 'block' : 'none'}}>
             <iframe src="https://embed.music.apple.com/us/playlist/fdpo-inspo/pl.u-EdAVzMesa6ZV4eW" width="80%" height="500" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            
              
              </div>
            <div style={{display: this.state.inspoPlaylist === 'Spotify' ? 'block' : 'none'}}>
              
            <iframe src="https://open.spotify.com/embed/playlist/5iLDnkSxMSTIKMtEMgvojB" width="80%" height="500" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>

              </div>
              <br></br>
              </center></div>
          
          <br></br>
          <center><Image src={Footer} className="footer"></Image></center>
          <br></br>
          <br></br>
          
      </div>
      

      )
  }
}
export default Main;


