import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Button from 'react-bootstrap/Button';
import FDPO from './content/NavBar.png';
import Footer from './content/footer.png';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Countdown from 'react-countdown';
import Touch from './Touch';
import './FDPO.css';
import { Redirect } from "react-router-dom";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Launch extends React.Component { 

  //the states of emotion and source will be set to null initially until the user had filled out the form.
  constructor() {
    super();
    this.state = { 
      currentImage: 0,
      timeLeft: null,
    };
  }

  componentDidMount()
  {
    
  }
  
  render()
  {  
    /*let list = [Snippet1, Snippet2, Snippet3, Snippet4, Snippet5]
    list = list.sort(() => Math.random() - 0.5)*/
    /*var today = new Date();
    var date = (today.getMonth()+1)+'-'+today.getDate()*/
    let dropDate = new Date("29 Jul 2021 23:00:00 EST")

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    
    return (   
      <div style={{background: 'black'}}> {/* DO NOT REMOVE THIS DIV COMPONENT*/}
        <div className="container">
          <br></br>
        <Image src={FDPO} className="launchLogo"></Image>
            
            <br></br>
            
            <center>
            <h1>FOR DEMONSTRATION<br></br>PURPOSES ONLY</h1>

            <iframe src="https://www.youtube.com/embed/RIazQElooXE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br></br>
            <br></br>
            <h1>A DEMO TAPE BY</h1>
            <h1>zl!ster</h1>
            <br></br>
            <h1>RETURN IN</h1>
            <h1><Countdown date={dropDate} daysInHours="true"/></h1>
            <br></br>
            <h2>notify me</h2>
            <div style={{margin: '0 auto'}}>
              
              <h2>
              <Form className="mb-3" name="contact" method="POST" data-netlify="true" onSubmit="submit" netlify netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="contact" />
                <Form.Group>
                  <Form.Control type="tel" id="phone" name="phone"
                  pattern="[0-9]{10}"
                  required
                    required size="md" placeholder="999-999-9999" />
                  <Button variant="secondary" size="md" type="submit" >SUBMIT</Button>
                  
                </Form.Group>
            </Form>
</h2>
                {/*<input placeholder="(XXX)-XXX-XXXX"></input></h2>*/}
            </div>
            <br></br>
            <h1>unreleased track 001</h1>
            <iframe className="unreleased" src="https://bandcamp.com/EmbeddedPlayer/track=3237212113/size=small/bgcol=ffffff/linkcol=333333/transparent=true/" seamless><a href="https://zlister.bandcamp.com/track/mermaid">mermaid by zl!ster</a></iframe>
            {/*<iframe className="unreleased" src="https://bandcamp.com/EmbeddedPlayer/track=3237212113/size=small/bgcol=333333/linkcol=0f91ff/transparent=true/" seamless><a href="https://zlister.bandcamp.com/track/mermaid">mermaid by zl!ster</a></iframe>*/}
           
            <br></br>
            <br></br>
            <h1>unreleased track 002</h1>
            <iframe className="unreleased" src="https://bandcamp.com/EmbeddedPlayer/track=2422041424/size=small/bgcol=ffffff/linkcol=333333/transparent=true/" seamless><a href="https://zlister.bandcamp.com/track/chain-loose">chain loose by zl!ster</a></iframe>
            {/*<iframe className="unreleased" src="https://bandcamp.com/EmbeddedPlayer/track=2422041424/size=small/bgcol=333333/linkcol=0f91ff/transparent=true/" seamless><a href="https://zlister.bandcamp.com/track/chain-loose">chain loose by zl!ster</a></iframe>*/}
            
           <br></br>
           <br></br>
            <h1 style={{transform: 'rotate(-180deg)'}}>PRONOUNCED<br></br>"zee-lister"</h1>
            
            <br></br>
            <h1>  <a href="https://www.instagram.com/orlandosvault/?hl=en" target="_blank">INSTAGRAM</a></h1>
            <br></br>
         <Image src={Footer} className="footer"></Image>
          <br></br>
          <br></br>
            </center>

          </div>
     
        
      </div>

      )
      
  }
}
export default Launch;


