import React from 'react';
import ReactDOM from 'react-dom';
//import './FDPO.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Launch from './Launch'
import Main from './Main'
import Immersive from './experimental/Immersive';
import 'bootstrap/dist/css/bootstrap.min.css';



/*using react router we set a constant equal to whatever component
we would like to render*/
const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={Main}/>  
      <Route exact path="/*" component={Main}/>    
      
    </Switch>
  </Router>
)

/*the code below reads the path and renders component on a conditional basis.
i.e. /home throws two different components at different places...*/
ReactDOM.render(routing, document.getElementById("root"));


